import Timeline from 'timeline-vuejs';
<template>
  <div>
    <vue-modaltor :visible="this.open" @hide="this.open=false">
      <template #header>
        <v-row class="mx-sm-8 mx-2">
          <v-col cols="11">
            <h1>Histórico</h1>
          </v-col>
          <v-col cols="1">
            <button @click="closePop()"><i class="close-icon">X</i></button>
          </v-col>
        </v-row>
      </template>
      <template #body>
        <v-row class="mx-sm-8 mx-2">
          <v-col cols="4">
          </v-col>
          <v-col cols="8">
            <Timeline
              :timeline-items="timelineItems"
              :message-when-no-items="messageWhenNoItems"
              order="asc"/>
          </v-col>
        </v-row>
      </template>
    </vue-modaltor>
  </div>
</template>

<script>
import Timeline from "timeline-vuejs";

export default {
  name:"TimelineUser",
  components: {
    Timeline
  },
  props: {
    open: Boolean,
    dataUsers: Object
  },
  data: () => (
    {
    messageWhenNoItems: 'Sin registros',
    timelineItems: []
  }),
  created() {
    if(this.dataUsers.id){
      this.buildData();
    }
  },
  methods: {
    closePop() {
      this.$emit('update', false);
    },
    buildData(){
      const timeCreated = new Date(this.dataUsers.createdAt);
      const created = {
        from: timeCreated,
        title: 'Creado',
        description: timeCreated.getHours() + ':' + timeCreated.getMinutes(),
        showDayAndMonth: true
      };
      this.timelineItems.push(created);

      if(this.dataUsers.usuarioRevisionAt) {
        const timeRevision = new Date(this.dataUsers.usuarioRevisionAt);
        const revision = {
          from: timeRevision,
          title: this.dataUsers.userRevision.nombreCompleto,
          description: timeRevision.getHours() + ':' + timeRevision.getMinutes(),
          showDayAndMonth: true
        };
        this.timelineItems.push(revision);
      }
      if(this.dataUsers.usuarioCompletoAt) {
        const timeCompleto = new Date(this.dataUsers.usuarioCompletoAt);
        const completo = {
          from: timeCompleto,
          title: this.dataUsers.userCompleto.nombreCompleto,
          description: timeCompleto.getHours() + ':' + timeCompleto.getMinutes(),
          showDayAndMonth: true
        };
        this.timelineItems.push(completo);
      }
    }
  }
}
</script>

<style scoped>
.close-icon {
  font-size: 25px;
  font-weight: bold;
}
</style>
