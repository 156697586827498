<template>
  <div>
    <div class="d-flex justify-end">
        <v-btn :disabled="this.validadoDisable" @click="showTimeline()" type="button" color="primary" class="mr-4" >
            Historial
        </v-btn>
        <v-btn :disabled="!this.validadoDisable" @click="alertNexPrograming('check')" type="button" v-bind:color="this.btnRevisado" class="mr-4">
            Revisado
        </v-btn>
        <v-btn :disabled="this.validadoDisable" @click="alertNexPrograming('validate')" type="button" v-bind:color="this.btnValidado">
            Validado
        </v-btn>
    </div>
    <div v-if="disabledHistoric">
      <TimelineUser 
        :open="open"
        @update="closeTime($event)"
        :dataUsers="dataUsers"
        />
    </div>
  </div>
</template>
  
  <script>
import {mapActions} from "vuex";
import TimelineUser from "./TimelineUser";

export default {
  name: "CheckButtons",
  components: {
    TimelineUser
  },
  props: {
    tenderId: Number,
    formatosId: Number,
  },
  data() {
    return {
        btnRevisado: 'primary',
        btnValidado: 'primary',
        validadoDisable: true,
        open: false,
        dataUsers: {},
        disabledHistoric: false
    }
  },
  async created() {
    const estatus = await this.findOneEstatusLicitacion({id: this.formatosId, tenderId: this.tenderId});
    if(estatus) {
      this.btnRevisado = estatus.revision ? 'success' : 'primary';
      this.btnValidado = estatus.completado ? 'success' : 'primary';
      this.validadoDisable = !estatus.revision;
      this.dataUsers = estatus;
      this.disabledHistoric = true;
    }
  },
  methods: {
    ...mapActions('statusTender', ['updateStautsTender', 'findOneEstatusLicitacion']),
    ...mapActions('statusTender', ['findOneEstatusLicitacion']),
  
    async alertNexPrograming(type){
        const statusTenderData = {
          id: this.formatosId,
          tenderId: this.tenderId,
          data: {}
        };

      if(type === 'check') {
        statusTenderData.data.revision = 1;
        statusTenderData.data.usuarioRevisionAt = new Date();
        const response = await this.updateStautsTender(statusTenderData);
        if(response) {
          this.sweetAlertWarning('Se ha mandado a revision');
          await this.$router.push('/licitaciones_documentos/'+this.tenderId)
        }
      } else {
        statusTenderData.data.completado = 1;
        statusTenderData.data.usuarioCompletoAt = new Date();
        const response = await this.updateStautsTender(statusTenderData);
        if(response) {
          this.sweetAlertWarning('Se ha validado correctamente');
          await this.$router.push('/licitaciones_documentos/'+this.tenderId)
        }
      }
    },
    showTimeline() {
      this.open = true;
    },
    closeTime(openValue) {
      this.open = openValue;
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>