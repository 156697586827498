<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Anexos</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#formSpec">
            Anexos Especificaciones Técnicas
          </v-tab>
          <v-tab-item value="formSpec">
            <v-form ref="formSpec" class="mt-10" @submit.prevent="submitFormSpec">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Título</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Título</h1>
                        <trumbowyg
                            v-model="data.titulo"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Título"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Requerimiento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Requerimiento
                        </h1>
                        <trumbowyg
                            v-model="data.requerimiento"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Requerimiento"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Especificaciones Técnica</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Especificaciones Técnica</h1>
                        <trumbowyg
                            v-model="data.especificaciones_tecnicas"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Especificaciones Técnica"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Proveedor Entrega</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Proveedor Entrega
                        </h1>
                        <trumbowyg
                            v-model="data.proveedor_entrega"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Proveedor Entrega"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Condiciones</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Condiciones</h1>
                        <trumbowyg
                            v-model="data.condiciones"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Condiciones"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Garantías</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Garantías
                        </h1>
                        <trumbowyg
                            v-model="data.garantias"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Garantías"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Anticipo</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Anticipo</h1>
                        <trumbowyg
                            v-model="data.anticipo"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Anticipo"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Forma de Pago</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Forma de Pago
                        </h1>
                        <trumbowyg
                            v-model="data.forma_pago"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Forma de Pago"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" lg="6" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="6" class="mt-4">
                  <v-btn type="button" @click="generateData" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-row class="mt-5">

                  <v-col cols="3">
                    <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServer" color="primary" style="width: 200px">
                      Descargar archivo
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="documentGenerateStatus">
                    <h1 class="mt-4 mx-2 ">Visualización de documento
                    </h1>
                    <trumbowyg
                        v-model="documentGenerate"
                        disabled="disabled"
                        name="body"
                        :config="configs.advanced"
                        placeholder="Visualización de documento"
                        class="form-control"
                    ></trumbowyg>
                  </v-col>
                </v-row>

              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#form">
            Descargar Anexos 4.1
          </v-tab>
          <v-tab-item value="form">
            <v-col cols="12" class="mt-4">
              <v-btn type="button"  @click="generateAnex" color="primary" style="width: 200px">
                Generar archivo
              </v-btn>
            </v-col>
            <v-row class="mt-5">
              <v-col cols="3">
                <v-btn type="button" v-if="documentGenerateStatusAnex" @click="downloadFileServerAnex" color="primary"
                       style="width: 200px">
                  Descargar archivo
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="documentGenerateStatusAnex">
                <h1 class="mt-4 mx-2 ">Visualización de documento
                </h1>
                <trumbowyg
                    v-model="documentGenerateAnex"
                    disabled="disabled"
                    name="body"
                    :config="configs.advanced"
                    placeholder="Visualización de documento"
                    class="form-control"
                ></trumbowyg>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab href="#formFianza">
            Descargar Anexos Fianza 4.2
          </v-tab>
          <v-tab-item value="formFianza">
            <v-col cols="12" class="mt-4">
              <v-btn type="button"  @click="generateFinance" color="primary" style="width: 200px">
                Generar archivo
              </v-btn>
            </v-col>
            <v-row class="mt-5">
              <v-col cols="3">
                <v-btn type="button" v-if="documentGenerateStatusFinance" @click="downloadFileServerFinance" color="primary"
                       style="width: 200px">
                  Descargar archivo
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="documentGenerateStatusFinance">
                <h1 class="mt-4 mx-2 ">Visualización de documento
                </h1>
                <trumbowyg
                    v-model="documentGenerateFinance"
                    disabled="disabled"
                    name="body"
                    :config="configs.advanced"
                    placeholder="Visualización de documento"
                    class="form-control"
                ></trumbowyg>
              </v-col>
            </v-row>
          </v-tab-item>

        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'

export default {
  name: "DetaiAnnexe",
  data() {
    return {
      overlay: false,
      tenderId: null,
      documentGenerateStatusAnex:false,
      documentGenerateAnex:'',
      documentGenerateFinance:false,
      documentGenerateStatusFinance:false,
      idAnex:20,
      idFinance:21,
      externalId: null,
      documentComplete:null,
      documentGenerate:null,
      documentGenerateStatus:false,
      id: null,
      formatosId:null,
      data: {
        tenderId: null,
        formatId: null,
        request: "",
        titulo: '',
        requerimiento: '',
        especificaciones_tecnicas: '',
        proveedor_entrega: '',
        condiciones: '',
        garantias: '',
        anticipo: '',
        forma_pago: ''
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('annexes', ['updateAnnexe', 'findOneAnnexe','generateHtmlFormatAnx','downloadFileAnx']),
    ...mapActions('anx_performnace_bond', ['updateAnxPerformance', 'findOneAnnexe','generateHtmlFormatAnxPB','downloadFileAnxPB']),
    ...mapActions('anx_spec_tecnic', ['updateAnxSpec', 'findOneAnxSpec','generateHtmlFormatAnxSpec','downloadFileAnxSpec']),
    ...mapActions('tenders', ['findOneTenders']),
    async downloadFileServerAnex() {
      this.overlay = true;
      await this.downloadFileAnx({id: this.idAnex, externalId:this.tenderId});
      this.overlay = false;
    },
    async downloadFileServerFinance() {
      this.overlay = true;
      await this.downloadFileAnx({id: this.idFinance, externalId: this.tenderId});
      this.overlay = false;
    },
    async generateFinance(){
      this.overlay = true
      const response = await this.generateHtmlFormatAnx({id:this.idFinance, tenderId: this.tenderId});
      if (!response.error) {
        this.documentGenerateFinance = response;
        this.documentGenerateStatusFinance = true;
      } else {
        this.documentGenerateFinance = response;
        this.documentGenerateStatusFinance = false;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async generateAnex(){
      this.overlay = true
      const response = await this.generateHtmlFormatAnx({id:this.idAnex, tenderId: this.tenderId});
      if (!response.error) {
        this.documentGenerateAnex = response;
        this.documentGenerateStatusAnex = true;
      } else {
        this.documentGenerateAnex = response;
        this.documentGenerateStatusAnex = false;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async findMarketOne() {
      const response = await this.findOneAnxSpec({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.anticipo = response.anticipo;
        this.data.condiciones = response.condiciones;
        this.data.especificaciones_tecnicas = response.especificaciones_tecnicas;
        this.data.forma_pago = response.forma_pago;
        this.data.garantias = response.garantias;
        this.data.proveedor_entrega = response.proveedor_entrega;
        this.data.requerimiento = response.requerimiento;
        this.data.titulo = response.titulo;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired(){
      if (!this.data.clave_licitacion){
        this.sweetAlertWarning('Campo requerimiento necesario');
        return false
      }
      if (!this.data.nombre_licitacion){
        this.sweetAlertWarning('Campo especificacions de servicio requerido');
        return false
      }
      return true

    },
    async validateRequiredFianza(){
      if (!this.data.clave_licitacion){
        this.sweetAlertWarning('Campo Clave de Licitación es requerido');
        return false
      }
      if (!this.data.nombre_licitacion){
        this.sweetAlertWarning('Campo Nombre de Licitación es requerido');
        return false
      }
      return true;
    },
    async validateRequiredSpec(){
      if (!this.data.titulo){
        this.sweetAlertWarning('Campo Título es requerido');
        return false
      }
      if (!this.data.requerimiento){
        this.sweetAlertWarning('Campo Requerimiento es requerido');
        return false
      }
      if (!this.data.especificaciones_tecnicas){
        this.sweetAlertWarning('Campo Especificaciones Técnicas es requerido');
        return false
      }
      if (!this.data.proveedor_entrega){
        this.sweetAlertWarning('Campo Proveedor de Entrega es requerido');
        return false
      }
      if (!this.data.condiciones){
        this.sweetAlertWarning('Campo Condiciones es requerido');
        return false
      }
      if (!this.data.garantias){
        this.sweetAlertWarning('Campo Garantías es requerido');
        return false
      }
      if (!this.data.anticipo){
        this.sweetAlertWarning('Campo Anticipo es requerido');
        return false
      }
      if (!this.data.forma_pago){
        this.sweetAlertWarning('Campo Forma de Pago es requerido');
        return false
      }
      return true;
    },
    async submitFormSpec() {
      const validateData = await this.validateRequiredSpec();
      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.updateAnxSpec({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormatAnxSpec({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFileAnxSpec({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming(){
      this.sweetAlertWarning('Proximamente')
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
